@import url(https://fonts.googleapis.com/css?family=Roboto:300,300italic,700,700italic);
@import url(https://cdnjs.cloudflare.com/ajax/libs/normalize/5.0.0/normalize.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/milligram/1.3.0/milligram.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

label,
input {
  font-size: x-large;
  color: white;
}
.padded {
  padding: 1em 0.5em !important;
}

/* Remove spinner */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/* Github Corner */
.github-corner {
  fill: #64ceaa;
  color: #fff;
  position: absolute;
  top: 0;
  border: 0;
  right: 0;
}

.github-corner:hover .octo-arm {
  -webkit-animation: octocat-wave 560ms ease-in-out;
          animation: octocat-wave 560ms ease-in-out;
}

.octo-arm {
  transform-origin: 130px 106px;
}

@-webkit-keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }

  20%,
  60% {
    transform: rotate(-25deg);
  }

  40%,
  80% {
    transform: rotate(10deg);
  }
}

@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }

  20%,
  60% {
    transform: rotate(-25deg);
  }

  40%,
  80% {
    transform: rotate(10deg);
  }
}

@media (max-width: 500px) {
  .github-corner:hover .octo-arm {
    -webkit-animation: none;
            animation: none;
  }

  .github-corner .octo-arm {
    -webkit-animation: octocat-wave 560ms ease-in-out;
            animation: octocat-wave 560ms ease-in-out;
  }
}

